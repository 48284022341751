/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  userDetails,
  SERVICE_REGION_IDENTIFIER,
  homepage_url,
  AVAILABLE_PAYMENT_PROCESSORS_KEY,
  MERCHANT_PORTAL,
  SHOPPING_URL,
  BUSINESS_LANDING_PAGE
} from "../network/constants";

export const toNumber = (value) => value.replace(",", "");

export function getParameterByName(name, url = window.location.href) {
  if (!name) return null;
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (results == null) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const getUser = () => {
  return JSON.parse(sessionStorage.getItem(userDetails) || "{}");
};

export const setUserDetails = (data) => {
  sessionStorage.setItem(userDetails, JSON.stringify({ ...data }));
};

export const setAvailablePaymentProcessorsToLocalStorage = (data)=> localStorage.setItem(AVAILABLE_PAYMENT_PROCESSORS_KEY, JSON.stringify(data));

export const getAvailablePaymentProcessorsLocalStorage = () => JSON.parse(localStorage.getItem(AVAILABLE_PAYMENT_PROCESSORS_KEY) || '[]');

export const isUserLoggedIn = () => {
  let checker;
  if (getUser()?.token) {
    checker = true;
  } else {
    checker = false;
  }
  return checker;
};

export const getServiceRegion = () => {
  const region_object = sessionStorage.getItem(SERVICE_REGION_IDENTIFIER);
  if (region_object) {
    const { country_name, id, redirect_url } = JSON.parse(region_object);
    return { country_name, id, redirect_url };
  }

  return null;
};

export const setServiceRegion = (country_name, id, redirect_url) =>
  sessionStorage.setItem(
    SERVICE_REGION_IDENTIFIER,
    JSON.stringify({ country_name, id, redirect_url })
  );

export const deleteServiceRegion = () =>
  sessionStorage.removeItem(SERVICE_REGION_IDENTIFIER);

export const getTimeZone = () => {
  const date = new Date();
  return date.getTimezoneOffset();
};
export const checkNull = (params) => {
  for (const key in params) {
    if (params[key] === null && params[key] === "" && params === undefined) {
      return false;
    }
    return true;
  }
};

export const getBusinessWithAfiariUrl = () => {
  // if (isUserLoggedIn()) {
  //   return `${BUSINESS_LANDING_PAGE}/auth?Bearer_Token=${getUser()?.token}&sourceUrl=${BUSINESS_LANDING_PAGE}`;
  // }
  return BUSINESS_LANDING_PAGE;
};

export const getLandingPageUrl = () => {
  let redirect_url = homepage_url;

  if (isUserLoggedIn()) {
    redirect_url = `${homepage_url}/auth?Bearer_Token=${getUser()?.token}&sourceUrl=${homepage_url}`;
  }

  return redirect_url;
};

export const getBlogPageUrl = () => {

  if (isUserLoggedIn()) {
    return `${homepage_url}/auth?Bearer_Token=${getUser()?.token}&sourceUrl=${homepage_url}/blog`;
  }

  return `${homepage_url}/blog`;
};

export const getShoppingUrl = ()=>{
  if(isUserLoggedIn()){
    return `${SHOPPING_URL}/auth?Bearer_Token=${getUser()?.token}&sourceUrl=${SHOPPING_URL}`;
  }

  return SHOPPING_URL;
}

export const getMerchantPortalUrl = ()=>{
  if(isUserLoggedIn()){
    return `${MERCHANT_PORTAL}/auth?Bearer_Token=${getUser()?.token}&sourceUrl=${MERCHANT_PORTAL}`;
  }

  return MERCHANT_PORTAL;
}
