import React, { useState, useEffect } from "react";
import BusinessForm from "../../components/BusinessForm/BusinessForm";
import { useMultistepForm } from "../../hooks/useMultistepForm";
import "./index.scss";
import { Link } from "react-router-dom";
import { MerchantRegistration } from "../../constants";
import { useNavigate } from "react-router-dom";
import { getUser, isUserLoggedIn } from "../../tools/utils";

//The code structure and commented code was left that way incase we fall back to a multi-step form

let BusinessData = {
  Name: "",
  PhoneNumber: "",
  Email: "",
  Description: "",
  subscriptionPlanId: "",
};
let StoreData = {
  Name: "",
  Email: "",
  ContactPhone: "",
  FullAddress: "",
  Longitude: 0,
  Latitude: 0,
  DailCode: "+234",
  BusinessId: "",
  City: "Lekki",
  Country: "Nigeria",
  Description: "",
  FileExtension: null,
  FileName: null,
  Mime: null,
  State: "Lagos",
  StoreImage: null,
};
let BankData = {
  BankCode: null,
  AccountName: "",
  AccountNumber: "",
  BankName: "",
  StoreId: "",
};
const INITIAL_DATA = {
  BusinessData,
  StoreData,
  BankData,
};

const AfiariMerchant = () => {
  useEffect(() => {
    document.title = "Afiari-merchant | onboarding";
  }, []);

  let userInfo = getUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate(`/auth?sourceUrl=${window.location.origin}/AfiariMerchantForm`);
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(userInfo?.user_id);
  const [FilledForm, setFilledForm] = useState(() => {
    const savedFormData = JSON.parse(
      localStorage.getItem(MerchantRegistration)
    );
    return savedFormData || INITIAL_DATA;
  });

  useEffect(() => {
    localStorage.setItem(MerchantRegistration, JSON.stringify(FilledForm));
  }, [FilledForm]);

  function updateBusinessData(fields) {
    setFilledForm((prev) => ({
      ...prev,
      BusinessData: {
        ...prev.BusinessData,
        ...fields,
      },
    }));
  }
  function updateStoreData(fields) {
    setFilledForm((prev) => ({
      ...prev,
      StoreData: {
        ...prev.StoreData,
        ...fields,
      },
    }));
  }
  function updateBankData(fields) {
    setFilledForm((prev) => ({
      ...prev,
      BankData: {
        ...prev.BankData,
        ...fields,
      },
    }));
  }

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <BusinessForm
        BusinessData={FilledForm.BusinessData}
        updateFields={updateBusinessData}
        userId={userId}
        setUserId={setUserId}
        userInfo={userInfo}
      />,
    ]);

  return (
    <>
      <div className={`merchant ${loading ? "loading" : ""}`}>
        <div className="merchant_image">
          <img
            src={`${
              currentStepIndex > 0
                ? "/assets/images/basket.jpg"
                : "/assets/images/woman-shoping.jpg"
            } `}
            alt="afiari merchant"
          />
        </div>
        <form
          className="merchant_form"
          // onSubmit={onSubmit}
        >
          <figure className="merchant_form_logo">
            <Link to="/">
              <img src="/assets/images/Afiari-logo.jpg" alt="Logo" />
            </Link>
          </figure>
          {/* <div className="merchant_form_stepslevel">
            <MerchantRegSteps currentStepIndex={currentStepIndex} />
          </div> */}
          {step}
          <div className="merchant_form_btns">
            {!isFirstStep && (
              <button
                className="merchant_form_btns_back"
                type="button"
                onClick={back}
              >
                Back
              </button>
            )}
            {/* <button
              className={`merchant_form_btns_cont ${loading ? "loading" : ""}`}
              type="submit"
            >
              {isLastStep ? "Done" : "Continue"}
            </button> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default AfiariMerchant;
