import React from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import "./index.scss";
import { Link } from "react-router-dom";

import {getMerchantPortalUrl} from '../../tools/utils';

const socials = [
  {
    logo: <FaTwitter />,
    label: "Twitter",
    link: "https://www.twitter.com/afiaridotcom",
  },
  {
    logo: <FaInstagram />,
    label: "Instagram",
    link: "https://www.instagram.com/afiaridotcom",
  },
  {
    logo: <FaFacebookSquare />,
    label: "Facebook",
    link: "https://www.facebook.com/afiaridotcom",
  },
  {
    logo: <FaLinkedin />,
    label: "LinkedIn",
    link: "https://www.linkedin.com/company/afiari/",
  },
  {
    logo: <FaWhatsapp />,
    label: "Whatsapp",
    link: "https://wa.link/bfgz73",
  },
];

const Footer = () => {

  const redirectToMerchantPortal = ()=> window.location.replace(getMerchantPortalUrl())

  const SocialCard = ({ data }) => {
    return (
      <a target="_blank" href={data.link} rel="noreferrer">
        <div className="footer_container_links_socials_link">
          <p className="footer_container_links_socials_link_icon">
            {data.logo}
          </p>
          <span className="footer_container_links_socials_link_text">
            {data.label}
          </span>
        </div>
      </a>
    );
  };
  return (
    <section className="footer">
      <div className="footer_container">
        <figure className="footer_container_logo">
          <Link to="/">
            <img src="/assets/images/Afiari-logo.png" alt="Logo" />
          </Link>
        </figure>
        <div className="footer_container_links">
          <div className="footer_container_links_socials">
            {socials.map((label) => (
              <SocialCard key={label.label} data={label} />
            ))}
          </div>

          <div className="footer_container_links_mid">
            <ul className="footer_container_links_mid_product ">
              <li className="footer_container_links_mid_product_h ">
                Product and services
              </li>
              <a href="/stores">Stores around you</a>
              <li onClick={redirectToMerchantPortal}>Merchant portal</li>
              <li>Market prices</li>
            </ul>
            <ul className="footer_container_links_mid_afiari ">
              <li className="footer_container_links_mid_afiari_h ">
                Afiari and Customers
              </li>
              <a href="/home">Home</a>
              <li>About Us</li>
              <a href="/">Business with Afiari</a>
              <li>Careers</li>
              <a href="/blog">Blog</a>
              <li>Privacy Policy & Terms</li>
              <li>Contact Us</li>
            </ul>
          </div>

          <ul className="footer_container_links_operate ">
            <li className="footer_container_links_operate_h">
              Where we operate
            </li>
            <li>Lekki</li>
            <li>Victoria Island</li>
            <li>Ajah</li>
            <li>Ikeja</li>
            <li>Gbagada</li>
            <li>See more...</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;
