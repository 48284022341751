import React, { useEffect, useLayoutEffect } from "react";
import { useAnimate } from "framer-motion";
import "./index.scss";

const Cater = () => {
  const data = [
    {
      id: "1",
      topic: "Supermarkets",
      bgPic: "supermarkets",
      content:
        "Single and multi-chain supermarkets can now sell online seamlessly",
    },
    {
      id: "2",
      topic: "Manufacturers",
      bgPic: "manufacturers",
      content:
        "Have an end-to-end outlook of your sales process both online and offline",
    },
    {
      id: "3",
      topic: "Retail Stores",
      bgPic: "retailStores",
      content: "Retailers can automate their sales on one platform",
    },
    {
      id: "4",
      topic: "Importers",
      bgPic: "importers",
      content: "Looking to sell directly to customers? We’ve got you covered",
    },
  ];

  const [scope, animate] = useAnimate();

  const handleAnimate = async () => {
    try {
      while (true) {
        await animate("#supermarkets", { scale: 1.2 }, { duration: 0.5 });
        await animate("#supermarkets", { scale: 1 }, { duration: 0.5 });
        await animate("#manufacturers", { scale: 1.2 }, { duration: 0.5 });
        await animate("#manufacturers", { scale: 1 }, { duration: 0.5 });
        await animate("#retailStores", { scale: 1.2 }, { duration: 0.5 });
        await animate("#retailStores", { scale: 1 }, { duration: 0.5 });
        await animate("#importers", { scale: 1.2 }, { duration: 0.5 });
        await animate("#importers", { scale: 1 }, { duration: 0.5 });
      }
    } catch (error) {
      console.error("An error occurred during animation:", error);
    }
  };

  const isTablet = () => {
    return window.innerWidth <= 920; // Adjust the threshold according to your requirements
  };

  useEffect(() => {
    if (!isTablet()) {
      setTimeout(() => {
        handleAnimate();
      }, 5000);
    }
  }, []);

  return (
    <section ref={scope} className="cater">
      <div className="cater_wrapper">
        <h2 className="cater_wrapper_h">We cater to...</h2>
        <div className="cater_wrapper_cards">
          {data.map((cardData, index) => {
            return (
              <div
                key={cardData.id}
                id={`${cardData.bgPic}`}
                className={`cater_wrapper_cards_container ${cardData.bgPic}`}
              >
                <div className="cater_wrapper_cards_container_wrapper">
                  <h2 className="cater_wrapper_cards_container_wrapper_h">
                    {cardData.topic}
                  </h2>
                  <p>{cardData.content}</p>
                </div>
                <div className="cater_wrapper_cards_container_overlay"></div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Cater;
