import React, { useEffect } from "react";
import { isUserLoggedIn, getMerchantPortalUrl } from "../../tools/utils";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const Successful = ({ msg }) => {
  const navigate = useNavigate();

  const url = getMerchantPortalUrl()

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate("/");
    }
  }, []);
  return (
    <main className="successful">
      <div className="successful_wrapper">
        <div className="successful_wrapper_heading">
          <h2>Registration Successful</h2>
          <figure className="successful_wrapper_heading_pic">
            <img src="/assets/images/partying-face.png" alt="party-face" />
          </figure>
        </div>
        <p className="successful_wrapper_p">{msg}</p>
        <a href={`${url}`}>
          <button className="successful_wrapper_btn">Go to Dashboard</button>
        </a>
      </div>
      <figure className="successful_partycap">
        <img src="/assets/images/party-popper.png" alt="party-cap" />
      </figure>
      <figure className="successful_partyface">
        <img src="/assets/images/party-face.png" alt="party-face" />
      </figure>
    </main>
  );
};

export default Successful;
