import React from 'react'

const FormWrapper = ({children}) => {
  return (
    <>
        <div className='formWrapper-body'>
            <div className='formWrapper-body_children'>
                {children}
            </div>
        </div>
    </>
  )
}

export default FormWrapper