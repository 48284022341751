import React from "react";
import "./index.scss";

const Companies = () => {
  const logos = [
    "gran.png",
    "edge.png",
    "jaydel.png",
    "adiba.png",
    "compramart.png",
  ];
  return (
    <section className="companies">
      <h2>Trusted by Companies of all sizes</h2>
      <div className="companies_wrapper">
        {logos.map((logo, index) => {
          return (
            <div className="companies_wrapper_logo" key={index}>
              <img src={`/assets/images/${logo}`} alt="companies_logo" />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Companies;
