import React from "react";
import "./index.scss";
import Accordion from "../Accordion/Accordion";

const Faq = () => {
  const data = [
    {
      id: "1",
      topic: "What will I get if I register on your platform?",
      content:
        "Depending on your plan, you will get an online store, inventory and point of sales software, merchant dashboard, and delivery partner",
    },
    {
      id: "2",
      topic: "Do I have to pay to onboard my store?",
      content: "You can get started with the free plan",
    },
    {
      id: "3",
      topic: "Who handles delivery to customers?",
      content:
        "We have delivery partners that deliver items to customers in due time",
    },
    {
      id: "4",
      topic: "We have more than one store location, can your product serve us?",
      content:
        "Yes, you can manage as many store locations as you want from the application",
    },
    {
      id: "5",
      topic: "How can I register my business ?",
      content:
        "You can register by clicking on the get started button on this page",
    },
  ];
  return (
    <section className="faq">
      <div className="faq_wrapper">
        <h2 className="faq_wrapper_h">FAQs</h2>
        <div className="faq_wrapper_qna">
          <hr />
          {data.map((data, index) => {
            return (
              <Accordion
                key={index}
                topic={data.topic}
                content={data.content}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
