import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import "./index.scss";
import { Link } from "react-router-dom";

const Accordion = ({ topic, content, btnLink }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={`accordion ${btnLink ? "acc-offline" : "acc-faq"} ${
        isActive ? "active" : ""
      }`}
    >
      <div onClick={() => setIsActive(!isActive)} className="accordion_top">
        <h2 className="accordion_top_h">{topic}</h2>
        <p className="accordion_top_arrow">
          {isActive ? <FaAngleUp /> : <FaAngleDown />}
        </p>
      </div>
      <div className="accordion_content">
        <p>{content}</p>
        {btnLink && (
          <Link to={btnLink}>
            <button className="accordion_content_btn">Create an Account</button>
          </Link>
        )}
      </div>
      <hr />
    </div>
  );
};

export default Accordion;
