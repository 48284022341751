import React from "react";
import "./index.scss";
import Accordion from "../Accordion/Accordion";

const OfflineStore = () => {
  const data = [
    {
      id: "1",
      topic: "Mini website or personalized sales page",
      content: "manage thousands of SKUs from your online store easily",
      btnLink: "/AfiariMerchantForm",
    },
    {
      id: "2",
      topic: "Access to customers details and reach them",
      content:
        "Know who your customers are and be able to reach them whenever you want",
      btnLink: "/AfiariMerchantForm",
    },
    {
      id: "3",
      topic: "Automate sales process",
      content: "Sell and issue receipts to customers both in-store and online",
      btnLink: "/AfiariMerchantForm",
    },
    {
      id: "4",
      topic: "Track your inventory ",
      content: "Know what you have in stock from requisition to sales",
      btnLink: "/AfiariMerchantForm",
    },
    {
      id: "5",
      topic: "Marketplace for customers to find you",
      content:
        "List your business on a marketplace where customers can shop from you easily",
      btnLink: "/AfiariMerchantForm",
    },
  ];
  return (
    <section className="offline">
      <h1 className="offline_h">
        Bring your offline store and transactions online
      </h1>
      <div className="offline_container">
        <div className="offline_container_left">
          <hr />
          {data.map((data, index) => {
            return (
              <Accordion
                key={index}
                topic={data.topic}
                content={data.content}
                btnLink={data.btnLink}
              />
            );
          })}
        </div>
        <figure className="offline_container_right">
          <img src="/assets/images/Dashboard.png" alt="dashboard" />
        </figure>
      </div>
    </section>
  );
};

export default OfflineStore;
