const envStaging = {
  AFIARI_BASE_URL: process.env.REACT_APP_IDENTITY_BASE_URL,
  ECOMMERCE_BASE_URL: process.env.REACT_APP_COMMERCE_BASE_URL,
  MERCHANT_BASE_URL: process.env.REACT_APP_MERCHANT_BASE_URL,
  UTILITY_BASE_URL: process.env.REACT_APP_UTILITY_BASE_URL,
  MERCHANT_PORTAL: process.env.REACT_APP_MERCHANT_PORTAL,
  SHOPPING_URL: process.env.REACT_APP_SHOPPING_URL,
  BUSINESS_LANDING_PAGE: process.env.REACT_APP_BUSINESS_LANDING_PAGE_URL
};

export const {
  AFIARI_BASE_URL,
  ECOMMERCE_BASE_URL,
  MERCHANT_BASE_URL,
  UTILITY_BASE_URL,
  MERCHANT_PORTAL,
  SHOPPING_URL,
  BUSINESS_LANDING_PAGE
} = envStaging;
export const userDetails = "userDetails";
export const merchantID = "merchantId";
export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const homepage_url = process.env.REACT_APP_HOMEPAGE_URL;
export const SERVICE_REGION_IDENTIFIER = "AFIARI-SERVICE-REGION";
export const AVAILABLE_PAYMENT_PROCESSORS_KEY = "AvailablePaymentProcessors";
