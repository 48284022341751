import logo from "../../assets/images/afiariLogo.svg";

const CustomLoader = () => {
  return (
    <div className="absolute top-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-white">
      <img className="animate-pulse w-72" src={logo} alt="logo" />
    </div>
  );
};

export default CustomLoader;
