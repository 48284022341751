import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

import "./index.scss";
import { getUser } from "../../tools/utils";
// import { handleLogOut } from "../../network/api";

const Navbar = () => {

  const userInfo = getUser();

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <nav className="navbar">
      <div className="navbar_wrapper">
        <figure className="navbar_wrapper_logo">
          <Link to="/">
            <img src="/assets/images/Afiari-logo.png" alt="Logo" />
          </Link>
        </figure>
        <div
          className={`navbar_wrapper_list ${
            click ? "show-sidebar" : "nav-links"
          }`}
        >
          <ul className="navbar_wrapper_list_links">
            <a href="/home">Home</a>
            <a href="/stores">Stores around you</a>
            <li className="business">Business</li>
            <a href="/blog">Blog</a>
          </ul>
          {!userInfo?.username ? (
            <>
              <div className="navbar_wrapper_list_auth">
                  <a href="/signin" className="navbar_wrapper_list_auth_signin">Sign In</a>
                <div className="navbar_wrapper_list_auth_signup">
                    <a href="signup">Sign Up</a>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="navbar_wrapper_list_details">
                <figure className="navbar_wrapper_list_details_pic">
                  <img src={userInfo?.photo_url} alt="profile pic" />
                </figure>
                <div className="navbar_wrapper_list_details_info">
                  <p>{userInfo?.fullname}</p>
                  <p>{userInfo?.username}</p>
                  {/* <p onClick={handleLogOut}>Log Out</p> */}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="navbar_wrapper_hamburger" onClick={handleClick}>
          {!click ? <FaBars /> : <FaTimes />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
