import React, { useState } from "react";
import "./index.scss";
import Modal from "../DemoModal/Modal";

const Future = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <section className="future">
        <h1 className="future_h">The future is digital and so should you!</h1>
        <p className="future_p">
          We have created a special demo that will show you how to transform
          your business by taking it online while automatically managing your
          offline store. No you don’t need two or three solutions for this. All
          you need is ONE and that is what you get from us. Watch the demo here
        </p>
        <button onClick={() => setIsModalOpen(true)} className="future_btn">
          Watch Demo
        </button>
        <iframe
          className="future_vid"
          src="https://www.youtube.com/embed/AU8yDZ0R7iM"
        ></iframe>
      </section>
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default Future;
