import React, { useState } from "react";
import "./index.scss";
import { MERCHANT_SERVER } from "../../network/config";
import { merchantAxiosInstance } from "../../network/api";
import { toast } from "react-toastify";
import ReactPixel from 'react-facebook-pixel';

const Hero = () => {
  const [loading, setLoading] = useState(false);
  const [sendData, setSendData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    channel: 1,
  });

  const { sendDemo } = MERCHANT_SERVER.LEADGENERATION_CONTROLLER;

  const submitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    ReactPixel.track('Lead');

    try {
      const res = await merchantAxiosInstance.post(
        `${sendDemo.route}`,
        sendData
      );

      const { status, error_message } = res?.data;
      if (status) {
        toast.success("Demo sent successful");
        setLoading(false);
        setSendData({ ...sendData, email: "" });
      } else {
        toast.error(error_message);
        setLoading(false);
        return;
      }
    } catch (err) {
      toast.error("Couldn't send Demo. Something went wrong");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <section className="hero">
      <div className="hero_wrapper">
        <div className="hero_wrapper_cta">
          <h1>
            Sell physical products online <span>faster</span> and{" "}
            <span>better</span>
          </h1>
          <p>
            Never lose touch with your business. Manage offline and online
            sales, track inventory and supplies automatically.
          </p>
          <div className="hero_wrapper_cta_form">
            <input
              required
              type="email"
              value={sendData.email}
              onChange={(e) =>
                setSendData({ ...sendData, email: e.target.value })
              }
              placeholder="Enter your email address"
            />
            <div
              onClick={submitEmail}
              className={`hero_wrapper_cta_form_btn ${
                loading ? "loading" : ""
              }`}
            >
              Get a Demo {loading && <div class="spinner"></div>}
            </div>
          </div>
        </div>
        <div className="hero_wrapper_pic">
          <div className="hero_wrapper_pic_floater-one">
            <figure className="hero_wrapper_pic_floater-one_man">
              <img src="/assets/images/man.png" alt="man" />
            </figure>
            <div className="hero_wrapper_pic_floater-one_info">
              <p className="hero_wrapper_pic_floater-one_info_h">
                You have a new order in your store
              </p>
              <p className="hero_wrapper_pic_floater-one_info_p">
                view details
              </p>
            </div>
          </div>

          <div className="hero_wrapper_pic_floater-two">
            <figure className="hero_wrapper_pic_floater-one_man">
              <img src="/assets/images/man.png" alt="man" />
            </figure>
            <div className="hero_wrapper_pic_floater-one_info">
              <p className="hero_wrapper_pic_floater-one_info_h">
                You have a new order in your store
              </p>
              <p className="hero_wrapper_pic_floater-one_info_p">
                view details
              </p>
            </div>
          </div>

          <figure className="hero_wrapper_pic_main">
            <img src="../../assets/images/heropic.webp" alt="heroimage" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Hero;
