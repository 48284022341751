import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactPixel from 'react-facebook-pixel';
//import ENV from './network/constants';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

if(process.env.REACT_APP_ENVIRONMENT === 'Production'){
  ReactPixel.init('393131539190809', null, options);

  ReactPixel.pageView(); // For tracking page view
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
