import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from '../../components/CustomLoader/CustomLoader'


const Signup = () => {  
  const navigate = useNavigate();
    useEffect(() => {
  const signUp_route = `/auth?sourceUrl=${window.location.href}&action=signUp`;
  navigate(signUp_route);
    
    }, []);
  
    return (
      <div>
        <CustomLoader/>
      </div>
    )
  };
  
  export default Signup;