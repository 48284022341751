const API_ROUTES = {
  IDENTITY_SERVER: {
    AUTH_CONTROLLER: {
      signIn: {
        route: `/auth/validate/auth-token`,
        method: "GET",
      },
      validateToken: {
        route: "/auth/validate/bearer-token",
        method: "POST",
      },
      refreshToken: {
        route: "/auth/token/refresh",
        method: "POST",
      },
      logOut: {
        route: "/auth/logout",
        method: "POST",
      },
    },
    ADMIN_CONTROLLER: {
      getAllroles: {
        route: "/test/hello",
        method: "GET",
      },
    },
  },

  MERCHANT_SERVER: {
    MERCHANT_CONTROLLER: {
      submitBusinessRegForm: {
        route: "/merchant/self-service/register-my-business",
        method: "POST",
      },
      getMerchant:{
        route: "/merchant/self-service/get/my-business",
        method: "GET"
      }
    },
    LOOKUP_CONTROLLER: {
      getProductsCategory: {
        route: "/lookup/product-categories",
        method: "GET",
      },
      getPaymentOptions: {
        route: "/lookup/payment-options",
        method: "GET",
      },
      getAccessLevels: {
        route: "/lookup/access-levels",
        method: "GET",
      },
      getBanks: {
        route: "/lookup/bank-list",
        method: "GET",
    },
    verifyBank: {
        route: "/lookup/verify/account",
        method: "GET",
    },
    getPaystackPublickey: {
      route: '/lookup/paystack/publickey',
      method: 'GET',
    }
    },
    SUBSCRIPTION_CONTROLLER: {
      getSubscriptionPlan: {
        route: "/subscription/plans",
        method: "GET",
      },
      buyPlan: {
        route: "/subscription/buy/plan",
        method: "POST",
      },
      verifyPayment: {
        route: "/subscription/verify-transaction",
        method: "POST",
      },
      cancelPayment:{
        route:"/subscription/cancel-transaction",
        method: 'POST'
      }
    },
    LEADGENERATION_CONTROLLER: {
      sendDemo: {
        route: "/leadgeneration/send-demo-video",
        method: "POST",
      },
    },
  },
  UTILITY_SERVER: {
    PAYMENT_CONTROLLER: {
      getPaystackPublickey: {
        route: "/payment/paystack/publickey",
        method: "GET",
      },
      getBanks: {
        route: "/payment/paystack/banks",
        method: "GET",
      },
      verifyBank: {
        route: "/payment/paystack/verify/account",
        method: "GET",
      },
    },
  },

  ECOMMERCE_SERVER: {
    TRANSACTION_CONTROLLER: {
      getPaystackPublickey: {
        route: "/transaction/paystack/public-key",
        method: "GET",
      },
    },
  },
};

export const {
  IDENTITY_SERVER,
  MERCHANT_SERVER,
  UTILITY_SERVER,
  ECOMMERCE_SERVER,
} = API_ROUTES;
