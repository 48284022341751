import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { MERCHANT_SERVER } from "../../network/config";
import { merchantAxiosInstance } from "../../network/api";
import { toast } from "react-toastify";

const Modal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [sendData, setSendData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    channel: 1,
  });

  const { sendDemo } = MERCHANT_SERVER.LEADGENERATION_CONTROLLER;

  const submitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await merchantAxiosInstance.post(
        `${sendDemo.route}`,
        sendData
      );

      const { status, result, error_message, status_code } = res?.data;
      if (status) {
        toast.success("Demo sent successful");
        setLoading(false);
        onClose();
      } else {
        toast.error(error_message);
        setLoading(false);
        onClose();
        return;
      }
    } catch (err) {
      toast.error("Couldn't send Demo. Something went wrong");
      setLoading(false);
      console.log(err);
    }
  };

  return ReactDOM.createPortal(
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Input your email to get access to Demo</h2>
        <div className="modal-form">
          <input
            required
            type="email"
            value={sendData.email}
            onChange={(e) =>
              setSendData({ ...sendData, email: e.target.value })
            }
            placeholder="Enter your email address"
          />
          <div
            onClick={submitEmail}
            className={`modal-form_btn ${loading ? "loading" : ""}`}
          >
            Get a Demo {loading && <div class="spinner"></div>}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
