// import React, { useEffect } from "react";
import "./index.scss";
import { useEffect, useState } from "react";
import {
  getParameterByName,
  getUser,
  isUserLoggedIn,
  setUserDetails,
} from "../../tools/utils";
import { IDENTITY_SERVER } from "../../network/config";

import { toast } from "react-toastify";

const Preloader = () => {
  const [mainText, setMainText] = useState("performing verification request");
  const [subText, setSubText] = useState("Requesting redirect to server...");

  const BASE_URL = process.env.REACT_APP_IDENTITY_BASE_URL;
  const API_KEY = process.env.REACT_APP_IDENTITY_API_KEY;
  const IDENTITY_SERVER_URL = process.env.REACT_APP_IDENTITY_SERVER_URL;

  const source_url_key = "x-afiari-source";
  const popup_pointer = "x-afiari-popup";

  const verifyValidationToken = async (authToken) => {
    const url = `${BASE_URL}${IDENTITY_SERVER.AUTH_CONTROLLER.signIn.route}?authToken=${authToken}`;
    console.log(url, "url");

    const headersList = {
      Apikey: API_KEY,
    };

    const config = {
      method: IDENTITY_SERVER.AUTH_CONTROLLER.signIn.method,
      headers: headersList,
    };
    const response = await fetch(url, config);

    console.log("api response", response);

    return new Promise((resolve, reject) => {
      if (response.status === 200) {
        resolve(response.json());
      } else {
        setMainText("Error Occured");
        setSubText(
          "unable to retrive profile data from server. Its good practise to close the browser and try again."
        );
        reject("an error occured");
      }
    });
  };

  const verifyAccessToken = async (accessToken) => {
    const url = `${BASE_URL}${IDENTITY_SERVER.AUTH_CONTROLLER.validateToken.route}`;

    const headersList = {
      Apikey: API_KEY,
      "Content-Type": "application/json",
    };

    const payload = {
      bearerToken: accessToken,
    };

    const config = {
      method: IDENTITY_SERVER.AUTH_CONTROLLER.validateToken.method,
      headers: headersList,
      body: JSON.stringify(payload),
    };

    const response = await fetch(url, config);

    console.log("api response", response);

    return new Promise((resolve, reject) => {
      if (response.status === 200) {
        resolve(response.json());
      } else {
        setMainText("Error Occured");
        setSubText(
          "unable to retrive profile data from server. Its good practise to close the browser and try again."
        );
        reject("an error occured");
      }
    });
  };

  const setSourceUrl = (key) => {
    const value = getParameterByName("sourceUrl");
    if (value) {
      sessionStorage.setItem(key, JSON.stringify(value));
      return true;
    }

    return false;
  };

  const getSourceUrl = (key) => {
    const value = sessionStorage.getItem(key);

    if (value) {
      sessionStorage.removeItem(key);
      return JSON.parse(value);
    }

    return undefined;
  };

  const triggerRedirect = (action_param) => {
    sessionStorage.setItem(popup_pointer, JSON.stringify(true));
    if (!action_param) action_param = getParameterByName("action");
    if (action_param) {
      const auth_url =
        action_param === "signIn"
          ? `${IDENTITY_SERVER_URL}/auth/login?returnUrl=${window.location.origin}${window.location.pathname}`
          : `${IDENTITY_SERVER_URL}/auth/signup?returnUrl=${window.location.origin}${window.location.pathname}`;
      console.log("redirecting", auth_url);
      window.location.replace(auth_url);
    } else {
      if (isUserLoggedIn()) return true;
      window.location.replace(
        `${IDENTITY_SERVER_URL}/auth/login?returnUrl=${window.location.origin}${window.location.pathname}`
      );
    }
  };

  const executeAuthTokenValidation = async (token) => {
    setMainText("...please wait. Verifying details");
    setSubText("setting-up profile data and redirecting");

    const api_response = await verifyValidationToken(token);
    const { status, result, message } = api_response;

    if (status) {
      toast.success("Auth token validation was successful");
      setUserDetails(result);
      let url = getSourceUrl(source_url_key);

      sessionStorage.removeItem(popup_pointer);
      if (!url) url = window.location.origin;

      console.log("auth url validateor", url);
      window.location.href = url;
      return;
    }

    toast.error(`${message}`);
    setTimeout(() => {
      triggerRedirect("signIn");
    }, 2000);
  };

  const executeAccessTokenValidation = async (token) => {
    setMainText("...please wait. Verifying details");
    setSubText("setting-up profile data and redirecting");

    const api_response = await verifyAccessToken(token);
    const { status, result, message } = api_response;

    if (status) {
      toast.success("access token validation was successful");
      console.log(result, "Auth component");

      let url = getSourceUrl(source_url_key);
      sessionStorage.removeItem(popup_pointer);
      console.log("redirect here", url);

      setUserDetails(result);

      if (!url || url.includes("undefined")) url = window.location.origin;
      window.location.href = `${url}`;
      return;
    }

    toast.error(`${message}`);
    setTimeout(() => {
      triggerRedirect("signIn");
    }, 2000);
  };

  useEffect(() => {
    // execution stack
    setSourceUrl(source_url_key);

    const accessToken = getParameterByName("Bearer_Token");
    const authToken = getParameterByName("ValidationToken");

    if (authToken) executeAuthTokenValidation(authToken);

    if (accessToken) executeAccessTokenValidation(accessToken);

    if (!JSON.parse(sessionStorage.getItem(popup_pointer) || "false")) {
      setTimeout(triggerRedirect('signIn'), 6000);
    }

    if (
      JSON.parse(sessionStorage.getItem(popup_pointer) || "false") &&
      !authToken
    ) {
      setTimeout(triggerRedirect('signIn'), 6000);
    }

    if (isUserLoggedIn()) {
      const user_data = getUser();
      sessionStorage.setItem(
        source_url_key,
        JSON.stringify(`${window.location.origin}`)
      );
      executeAccessTokenValidation(user_data["token"]);
    }
  }, []);

  return (
    <div class="overflow" id="preload">
      <div class="circle-square">
        <div class="red">&nbsp;</div>
        <div class="blue">&nbsp;</div>
        <div class="green">&nbsp;</div>
        <div class="yellow">&nbsp;</div>
      </div>
    </div>
  );
};

export default Preloader;
