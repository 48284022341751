import { React, useState, useEffect } from "react";
import "./index.scss";

import PricingCard from "../PricingCard/PricingCard";
import { useNavigate } from "react-router-dom";
import { merchantAxiosInstance } from "../../network/api";
import { MERCHANT_SERVER } from "../../network/config";

const Pricing = () => {
  const url = `/AfiariMerchantForm`;
  const [subscriptionData, setSubscriptionData] = useState([]);

  const navigate = useNavigate();

  const redirect = () => navigate(url);

  const { getSubscriptionPlan } = MERCHANT_SERVER.SUBSCRIPTION_CONTROLLER;
  const subscriptionPricing = async function () {
    try {
      const response = await merchantAxiosInstance.get(
        `${getSubscriptionPlan.route}`
      );
      if (response?.data?.status) {
        let sub = response?.data?.result;
        setSubscriptionData(sub?.reverse());
      }
      console.log(subscriptionData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    subscriptionPricing();
  }, []);

  return (
    <section className="pricing">
      <h1 className="pricing_h">Get Afiari Pricing</h1>
      <p className="pricing_p">No cancellation fee. No Debit card. No Risk</p>
      <div className="pricing_slider">
        {subscriptionData?.map((sub) => {
          return (
            <>
              <PricingCard key={sub.id} subscription={sub} />
            </>
          );
        })}
      </div>
      <button className="pricing_btn" onClick={redirect}>
        Get Started Now
      </button>
      <div className="blurry"></div>
    </section>
  );
};

export default Pricing;
