import React, { useEffect } from "react";
import {getBlogPageUrl} from '../../tools/utils';
import CustomLoader from '../../components/CustomLoader/CustomLoader'


const Blog = () => {  
    useEffect(() => {
        window.location.replace(getBlogPageUrl());
    }, []);
  
    return (
      <div>
        <CustomLoader/>
      </div>
    )
  };
  
  export default Blog;