import React from 'react'
import "./index.scss"
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <section className='error-page'>
        <figure className='error-page_image'>
            <img src='/assets/images/error-img.png' alt='page not found' />
        </figure>
        <h1 className='error-page_h'>Oops! Page not Found</h1>
        <p className='error-page_p'>Sorry the page you are looking for does not exist. Please go back to the homepage</p>
        <Link to="/"><button className='error-page_btn'>Go to Homepage</button></Link>
    </section>
  )
}

export default PageNotFound