export const env = "Staging";
export const userDetails = "user-details";
export const MerchantRegistration = "AFIARI_MERCHANT_REGISTRATION_2.0";
export const BankList = "bank_list_2.0";
export const CompletedRegistration = "x-afiari-registarion-complete-2.0";
export const usersList = "users-list";
export const MERCHANT_SERVER = 1;
export const COMMERCE_SERVER = 2;
export const UTILITY_SERVER = 3;
export const IDENTITY_SERVER = 4;

export const IDENTITY_SERVER_URL = process.env.REACT_APP_IDENTITY_SERVER_URL;
export const IDENTITY_API_KEY = process.env.REACT_APP_IDENTITY_API_KEY;
export const IDENTITY_BASE_URL = process.env.REACT_APP_IDENTITY_BASE_URL;
export const MERCHANT_BASE_URL = process.env.REACT_APP_MERCHANT_BASE_URL;
export const MERCHANT_API_KEY = process.env.REACT_APP_MERCHANT_API_KEY;
export const UTILITY_API_KEY = process.env.REACT_APP_UTILITY_API_KEY;
export const UTILITY_BASE_URL = process.env.REACT_APP_UTILITY_BASE_URL;
export const COMMERCE_BASE_URL = process.env.REACT_APP_COMMERCE_BASE_URL;
export const COMMERCE_API_KEY = process.env.REACT_APP_COMMERCE_API_KEY;
export const MERCHANT_PORTAL = process.env.REACT_APP_MERCHANT_PORTAL;
