import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from '../../components/CustomLoader/CustomLoader'


const Signin = () => {  
  const navigate = useNavigate();
    useEffect(() => {
  const signIn_route = `/auth?sourceUrl=${window.location.href}&action=signIn`;
    navigate(signIn_route)
    }, []);
  
    return (
      <div>
        <CustomLoader/>
      </div>
    )
  };
  
  export default Signin;