import React from "react";
import "./index.scss";

const PricingCard = ({ subscription }) => {
  return (
    <div className="pricing-card">
      <p className="pricing-card_plan">{subscription.plan_name} Plan</p>
      <p className={`pricing-card_price ${subscription.pricePlan}`}>
        N{subscription.yearly_cost_text}/yr
      </p>
      <p className="pricing-card_benefit">
        {subscription.store_count ?? ""}{" "}
        {subscription.store_count > 1 ? "Stores" : "Store"}
      </p>
      <p className="pricing-card_benefit">
        {subscription.transaction_fee_text || ""} transaction fee
      </p>
      <p className="pricing-card_benefit">
        {subscription.product_count || ""} Products
      </p>
      <p className="pricing-card_benefit">
        {subscription.can_view_customer_info
          ? "Access to customers emails and phone numbers"
          : ""}
      </p>
      <p className="pricing-card_benefit">
        {subscription.number_of_users || ""} Users
      </p>
      <p className="pricing-card_benefit">
        {subscription.has_unique_link ? "Unique link " : ""}
      </p>
      <p className="pricing-card_benefit">
        {subscription.vendor_count
          ? `${subscription.vendor_count} Vendors`
          : ""}
      </p>
      <p className="pricing-card_benefit">
        {subscription.discount_rate
          ? `${subscription.discount_rate_text} Discounts`
          : ""}
      </p>

      <p className="pricing-card_benefit">
        {subscription.has_pos ? "Point of sale software" : ""}
      </p>
      <p className="pricing-card_benefit">
        {subscription.pos_terminal_count
          ? `${subscription.pos_terminal_count}  Point of sale ${
              subscription.pos_terminal_count > 1 ? "terminals" : "terminal"
            }`
          : ""}
      </p>
    </div>
  );
};

export default PricingCard;
