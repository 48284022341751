import { useEffect, useRef } from "react";
import "./index.scss";
import { register } from "swiper/element/bundle";
register();

// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

// import required modules
// import { Pagination, Navigation } from "swiper/modules";

const Testimonials = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      pagination: true,
      // These are new...
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            background-color: #0A503D;
            padding: 12px 12px;
            border-radius: 100%;
            width:25px;
            height: 25px;
            color: #fff;
            font-weight: 600;
          }
          @media screen and (max-width: 480px) {
            .swiper-button-next,
            .swiper-button-prev {
              width:12px;
              height: 12px;
            }
          }

          .swiper-pagination-bullet{
            width: 15px;
            height: 15px;
            background-color: #0A503D;
          }

          @media screen and (max-width: 480px) {
            .swiper-pagination-bullet {
              width:10px;
              height: 10px;
              
            }
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);

  return (
    <section className="testimonials">
      <h1 className="testimonials_h">Hear from our customers</h1>

      <swiper-container
        ref={swiperRef}
        init="false"
        // slidesPerView={1}
        // spaceBetween={30}
        // loop={false}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        // modules={[Pagination, Navigation]}
        // className="mySwiper"
      >
        {/* SLIDE ONE */}
        <swiper-slide>
          <div className="testimonials_main">
            <div className="testimonials_main_wrapper">
              {/* logo area */}
              <div className="testimonials_main_wrapper_outer-circle">
                <div className="testimonials_main_wrapper_outer-circle_inner-circle">
                  <figure className="testimonials_main_wrapper_outer-circle_inner-circle_logo">
                    <img src="/assets/images/jaydel.png" alt="logo" />
                  </figure>
                </div>
              </div>

              <div className="testimonials_main_wrapper_left">
                <p className="testimonials_main_wrapper_left_comment">
                  " I’m glad I came across this platform as it has helped me to
                  simplify my online sales. Now, I don’t have to think about
                  riders, website development or other technicalities. I just
                  sell online"
                </p>
                <p className="testimonials_main_wrapper_left_customer">
                  Jaydel Mart
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>

        {/* SLIDE TWO */}
        <swiper-slide>
          <div className="testimonials_main">
            <div className="testimonials_main_wrapper">
              {/* logo area */}
              <div className="testimonials_main_wrapper_outer-circle">
                <div className="testimonials_main_wrapper_outer-circle_inner-circle">
                  <figure className="testimonials_main_wrapper_outer-circle_inner-circle_logo">
                    <img src="/assets/images/edge.png" alt="logo" />
                  </figure>
                </div>
              </div>

              <div className="testimonials_main_wrapper_left">
                <p className="testimonials_main_wrapper_left_comment">
                  " We use this application to reach customers who don't visit
                  our store and it has been a great experience. I recommend"
                </p>
                <p className="testimonials_main_wrapper_left_customer">
                  D'Edge Supermarket
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>

        {/* SLIDE THREE */}
        <swiper-slide>
          <div className="testimonials_main">
            <div className="testimonials_main_wrapper">
              {/* logo area */}
              <div className="testimonials_main_wrapper_outer-circle">
                <div className="testimonials_main_wrapper_outer-circle_inner-circle">
                  <figure className="testimonials_main_wrapper_outer-circle_inner-circle_logo">
                    <img src="/assets/images/adiba.png" alt="logo" />
                  </figure>
                </div>
              </div>

              <div className="testimonials_main_wrapper_left">
                <p className="testimonials_main_wrapper_left_comment">
                  " We use this application to reach customers who don't visit
                  our store and it has been a great experience. I recommend"
                </p>
                <p className="testimonials_main_wrapper_left_customer">Adiba</p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </section>
  );
};

export default Testimonials;
