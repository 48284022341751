import React from "react";
import Navbar from "../../components/LandingNavbar/Navbar";
import Hero from "../../components/Hero/Hero";
import Companies from "../../components/Companies/Companies";
import "./index.scss";
import OfflineStore from "../../components/OfflineStore/OfflineStore";
import Future from "../../components/Future/Future";
import Testimonials from "../../components/Testimonials/Testimonials";
import Pricing from "../../components/Pricing/Pricing";
import Faq from "../../components/Faq/Faq";
import Cater from "../../components/Cater/Cater";
import Footer from "../../components/Footer/Footer";

const Landing = () => {
  console.log(process.env.REACT_APP_STAGING);
  return (
    <div className="landing">
      <div className="landing_top">
        <div className="landing_top_wrapper">
          <Navbar />
          <Hero />
          <Companies />
        </div>
        <div className="landing_top_overlay"></div>
      </div>
      <OfflineStore />
      <Cater />
      <Future />
      <Testimonials />
      <Pricing />
      <Faq />
      <Footer />
    </div>
  );
};

export default Landing;
